<template>
    <v-container fluid>
        
        <!-- Titulo e botões -->
        <v-row no-gutters>
            <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                <v-toolbar-title class="ml-2 mt-2">{{desdobramento.nome}}</v-toolbar-title>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8" xl="8" align="end"> 
                <v-btn
                    class="ml-2 my-1"
                    color="primary"
                    @click="exportar(false)"   
                >
                    <v-icon left>mdi-microsoft-excel</v-icon> Exportar
                </v-btn>
                <v-btn
                    v-if="fnCriarEditar"
                    class="ml-2 my-1"
                    color="primary"
                    @click="abrirImportar()"
                >
                    <v-icon left>mdi-upload</v-icon> Importar
                </v-btn> 
                <v-btn
                    v-if="fnCriarEditar && desdobramento.tipo == 'Faturamento'"
                    class="ml-2 my-1"
                    color="primary"
                    @click="faturamento()"
                >
                    <v-icon left>mdi-cash-sync</v-icon> Aualizar Faturamento
                </v-btn> 
                <v-btn
                    v-if="fnCriarEditar && desdobramento.tipo == 'Faturamento'"
                    class="ml-2 my-1"
                    color="primary"
                    @click="historicoFaturamento()"
                >
                    <v-icon left>mdi-cash-multiple</v-icon> Histórico Faturamento
                </v-btn> 
                <v-menu
                    v-model="menuConfiguracoes"
                    :close-on-content-click="false"
                    :nudge-width="200"
                    offset-y
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            class="ml-2 my-1"
                            icon
                            color="primary" 
                            v-bind="attrs"
                            v-on="on" 
                            title="Configurar Filtros" 
                        >
                            <v-icon>mdi-cog</v-icon>
                        </v-btn>
                    </template>
                    <v-card>
                        <v-list>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title><v-icon color="primary">mdi-filter-check</v-icon> Filtros Mantidos Durante a Navegação</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                        <v-divider></v-divider>
                        <v-list>
                            <v-list-item v-for="(item, index) in configuracoes" :key="index">
                                <v-switch
                                    v-model="item.ativo"
                                    color="primary"
                                    :label="item.nome"
                                    hide-details
                                    @change="changeConfiguracoes(item)"
                                ></v-switch>
                            </v-list-item>
                        </v-list>
                        <v-divider></v-divider>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="primary"
                                text
                                @click="menuConfiguracoes = false"
                            >
                                Fechar
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-menu>
            </v-col>
        </v-row>

        <!-- Filtros -->
        <v-card class="mt-2 py-4" elevation="1">
            <v-row no-gutters>
                <v-col cols="12" class="ml-4">
                    <span 
                        @click="mostrarOcultar('filtros')"
                        style="cursor: pointer; font-weight: bold;"
                    >
                        {{mostrar.filtros.nome}}
                        <v-icon
                            class="ml-2 mr-4"
                            color="primary"
                        >
                            {{mostrar.filtros.icone}}
                        </v-icon>
                    </span>
                </v-col>
            </v-row>
            <v-expand-transition>
                <v-row no-gutters v-show="mostrar.filtros.flag" class="mt-2">
                    <v-col cols="12" sm="12" md="4" lg="4" xl="4" class="px-2">
                        <v-select
                            label="Ano"
                            :items="lista.anos"
                            v-model="filtro.ano"
                            dense 
                            outlined
                        >
                        </v-select>
                    </v-col>
                    <v-col cols="12" sm="12" md="4" lg="4" xl="4" class="px-2">
                        <v-select
                            label="Planejado"
                            :items="[{text:'Todos', value: null}, {text:'Planejado', value: '> 0'}, {text:'Não Planejado', value: '= 0'}]"
                            v-model="filtro.planejado"
                            dense 
                            outlined
                        >
                        </v-select>
                    </v-col>
                    <v-col cols="12" sm="12" md="4" lg="4" xl="4" class="px-2">
                        <v-select
                            label="Histórico"
                            :items="[
                                {text:'Todos', value: null}, 
                                //{text:'Com Histórico', value: 'is not null'}, 
                                //{text:'Sem Histórico', value: 'is null'}, 
                                {text:'Base Atual', value: 'Base Atual'},
                                {text:'Base Antiga', value: 'Base Antiga'},
                            ]"
                            v-model="filtro.historico"
                            dense 
                            outlined
                        >
                        </v-select>
                    </v-col>
                    <v-col v-for="(obj, index) in desdobramento.tabelas" :key="index" v-show="!obj.fn_oculto" cols="12" sm="12" md="4" lg="4" xl="4" class="px-2">
                        <v-autocomplete
                            :label="obj.nome"
                            :items="lista[obj.tabela]"
                            v-model="filtro['id_'+obj.tabela]"
                            multiple
                            clearable
                            outlined
                            dense 
                        >
                            <template v-slot:prepend-item>
                                <v-list-item
                                ripple
                                @mousedown.prevent
                                @click="selecionarTudo(obj.var_id, obj.tabela)"
                                >
                                <v-list-item-action>
                                    <v-icon :color="filtro[obj.var_id].length > 0 ? 'indigo darken-4' : ''">
                                    {{ iconeSelecionarTudo(obj.var_id, obj.tabela) }}
                                    </v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title>
                                    Selecionar Tudo
                                    </v-list-item-title>
                                </v-list-item-content>
                                </v-list-item>
                                <v-divider class="mt-2"></v-divider>
                            </template>
                            <template v-slot:selection="{item, index}">
                                <div 
                                    v-if="filtro['id_'+obj.tabela].length === 1 && index === 0"
                                    style="width: 70% !important; white-space: nowrap !important; text-overflow: ellipsis !important; overflow: hidden;"
                                >{{ item.text }}</div>
                                <span
                                    v-if="filtro['id_'+obj.tabela].length > 1 && index === 0"
                                    class="grey--text caption mr-1"
                                >{{ filtro['id_'+obj.tabela].length }} selecionados</span>
                            </template>
                        </v-autocomplete>
                    </v-col>
                </v-row>
            </v-expand-transition>
            <v-expand-transition>
                <v-row no-gutters class="mb-2" v-show="mostrar.filtros.flag">
                    <v-col cols="12" sm="12" md="4" lg="4" xl="4" class="px-2">
                        <v-text-field
                            v-model="busca"
                            append-icon="mdi-magnify"
                            label="Pesquisar"
                            dense 
                            outlined
                            hide-details
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="4" lg="4" xl="4" class="px-2">
                    </v-col>
                    <v-col cols="12" sm="12" md="4" lg="4" xl="4"  class="px-2" align="end">
                        <v-btn
                            class="ml-2 mb-1"
                            color="primary"
                            @click="limpar()"
                        >
                            <v-icon left>mdi-filter-off</v-icon> Limpar
                        </v-btn> 
                        <v-btn
                            class="ml-2 mb-1"
                            color="primary"
                            @click="filtrar()"
                        >
                            <v-icon left>mdi-filter</v-icon> Filtrar
                        </v-btn>
                    </v-col>
                </v-row>
            </v-expand-transition>
        </v-card>

        <!-- Indicadores -->
        <v-card class="mt-2 py-4" elevation="1">
            <v-row no-gutters>
                <v-col cols="12" class="ml-4">
                    <span 
                        @click="mostrarOcultar('indicadores')"
                        style="cursor: pointer; font-weight: bold;"
                    >
                        {{mostrar.indicadores.nome}}
                        <v-icon
                            class="ml-2 mr-4"
                            color="primary"
                        >
                            {{mostrar.indicadores.icone}}
                        </v-icon>
                    </span>
                </v-col>
            </v-row>
            <v-expand-transition>
                <v-row no-gutters v-show="mostrar.indicadores.flag">
                        <div
                            v-for="indicador in indicadoresTotal"
                            :key="indicador.nome"
                        >
                            <v-tooltip top :color="indicador.cor" v-if="indicador.filtro">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-card
                                        :class="'elevation-1 mt-3 indicadoresCartao' + (indicador.ml ? indicador.ml : ' ml-3') + (indicador.mr ? indicador.mr : '')"
                                        :color="indicador.cor"
                                        v-bind="attrs"
                                        v-on="on"
                                        @click="filtroIndicadore(indicador.filtro)"
                                    >
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <div 
                                                    class="indicadoresTitulo"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                ><v-icon dark small>mdi-filter</v-icon> {{indicador.nome}}</div>
                                            </template>
                                            <span>{{indicador.desc}}</span>
                                        </v-tooltip>
                                        <div 
                                            class="indicadoresValor"
                                        >{{(indicador.valor*1).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}</div>
                                        <v-avatar 
                                            class="indicadoresIcone"
                                            size="46"
                                            :color="indicador.cor2 ? indicador.cor2 : indicador.cor + ' darken-1'"
                                        >
                                            <v-icon dark>{{indicador.icone}}</v-icon>
                                        </v-avatar>
                                        <v-progress-linear
                                            v-show="indicador.percSufixo"
                                            :value="indicador.perc"
                                            color="#fff"
                                            height="12"
                                            class="indicadoresPercemtualBarra"
                                        ></v-progress-linear>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <div 
                                                    v-show="indicador.percSufixo"
                                                    class="indicadoresPercemtualValor"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                >{{indicador.percNome}} ({{indicador.perc}}%)</div>
                                            </template>
                                            <span>{{indicador.percDesc}}</span>
                                        </v-tooltip>
                                    </v-card>
                                </template>
                                <span>{{indicador.filtroTitulo}}</span>
                            </v-tooltip>
                            <v-card
                                :class="'elevation-1 mt-3 indicadoresCartao' + (indicador.ml ? indicador.ml : ' ml-3') + (indicador.mr ? indicador.mr : '')"
                                :color="indicador.cor"
                                v-else
                            >
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <div 
                                            class="indicadoresTitulo"
                                            v-bind="attrs"
                                            v-on="on"
                                        >{{indicador.nome}}</div>
                                    </template>
                                    <span>{{indicador.desc}}</span>
                                </v-tooltip>
                                <div 
                                    class="indicadoresValor"
                                >{{(indicador.valor*1).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}</div>
                                <v-avatar 
                                    class="indicadoresIcone"
                                    size="46"
                                    :color="indicador.cor2 ? indicador.cor2 : indicador.cor + ' darken-1'"
                                >
                                    <v-icon dark>{{indicador.icone}}</v-icon>
                                </v-avatar>
                                <v-progress-linear
                                    v-show="indicador.percSufixo"
                                    :value="indicador.perc"
                                    color="#fff"
                                    height="12"
                                    class="indicadoresPercemtualBarra"
                                ></v-progress-linear>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <div 
                                            v-show="indicador.percSufixo"
                                            class="indicadoresPercemtualValor"
                                            v-bind="attrs"
                                            v-on="on"
                                        >{{indicador.percNome}} ({{indicador.perc}}%)</div>
                                    </template>
                                    <span>{{indicador.percDesc}}</span>
                                </v-tooltip>
                            </v-card>
                        </div>
                </v-row>
            </v-expand-transition>
        </v-card>

        <!-- Listagem de planejamento -->
        <v-card class="mt-2 py-4" elevation="1">
            <v-row no-gutters>
                <v-col cols="12" class="ml-4">
                    <span 
                        @click="mostrarOcultar('lista')"
                        style="cursor: pointer; font-weight: bold;"
                    >
                        {{mostrar.lista.nome}}
                        <v-icon
                            class="ml-2 mr-4"
                            color="primary" 
                        >
                            {{mostrar.lista.icone}}
                        </v-icon>
                    </span>
                </v-col>
            </v-row>
            <!-- Campos de exibição -->
            <v-expand-transition>
                <v-row no-gutters v-show="mostrar.lista.flag">
                    <v-col cols="12" sm="12" md="3" lg="3" xl="3" class="px-2 mb-4" v-if="desdobramento.periodo == 'Mensal'">
                        <v-select
                            label="Meses"
                            :items="meses"
                            v-model="mesesHeader"
                            dense 
                            outlined
                            multiple
                            clearable
                            hide-details
                        >
                            <template v-slot:prepend-item>
                                <v-list-item
                                ripple
                                @mousedown.prevent
                                @click="selecionarTudoExibicao('mesesHeader', 'meses')"
                                >
                                <v-list-item-action>
                                    <v-icon :color="mesesHeader.length > 0 ? 'indigo darken-4' : ''">
                                    {{ iconeSelecionarTudoExibicao('mesesHeader', 'meses') }}
                                    </v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title>
                                    Selecionar Tudo
                                    </v-list-item-title>
                                </v-list-item-content>
                                </v-list-item>
                                <v-divider class="mt-2"></v-divider>
                            </template>
                            <template v-slot:selection="{item, index}">
                                <div 
                                    v-if="mesesHeader.length === 1 && index === 0"
                                    style="width: 70% !important; white-space: nowrap !important; text-overflow: ellipsis !important; overflow: hidden;"
                                >{{ item }}</div>
                                <span
                                    v-if="mesesHeader.length > 1 && index === 0"
                                    class="grey--text caption mr-1"
                                >{{ mesesHeader.length }} selecionados</span>
                            </template>
                        </v-select>
                    </v-col>   
                    <v-col cols="12" sm="12" md="3" lg="3" xl="3" class="px-2 mb-4">
                        <v-select
                            label="Indicadores"
                            :items="indicadores"
                            v-model="indicadoresHeader"
                            dense 
                            outlined
                            multiple
                            clearable
                            hide-details
                        >
                            <template v-slot:prepend-item>
                                <v-list-item
                                ripple
                                @mousedown.prevent
                                @click="selecionarTudoExibicao('indicadoresHeader', 'indicadores', 'text')"
                                >
                                <v-list-item-action>
                                    <v-icon :color="mesesHeader.length > 0 ? 'indigo darken-4' : ''">
                                    {{ iconeSelecionarTudoExibicao('indicadoresHeader', 'indicadores') }}
                                    </v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title>
                                    Selecionar Tudo
                                    </v-list-item-title>
                                </v-list-item-content>
                                </v-list-item>
                                <v-divider class="mt-2"></v-divider>
                            </template>
                            <template v-slot:selection="{item, index}">
                                <div
                                    v-if="indicadoresHeader.length === 1 && index === 0"
                                    style="width: 70% !important; white-space: nowrap !important; text-overflow: ellipsis !important; overflow: hidden;"
                                >{{ item.text }}</div>
                                <span
                                    v-if="indicadoresHeader.length > 1 && index === 0"
                                    class="grey--text caption mr-1"
                                >{{ indicadoresHeader.length }} selecionados</span>
                            </template>
                        </v-select>
                    </v-col>
                </v-row>
            </v-expand-transition>
            <!-- Listagem -->
            <v-expand-transition>
                <v-data-table v-show="mostrar.lista.flag"
                    :headers="headers"
                    :items="dados"
                    :server-items-length="total" 
                    :items-per-page="5"
                    :footer-props="{
                        'items-per-page-options': [5, 10, 15, 25, 50, 100]
                    }"
                    :options.sync="pagination"
                    multi-sort
                    class="elevation-0 mx-2"
                >   
                    <template v-slot:header="{}">
                        <thead>
                            <tr>
                                <th v-for="(h, i) in headersGroup" :key="i" :colspan="h.colspan" :class="h.class">
                                    {{h.text}}
                                </th>
                            </tr>
                        </thead>
                    </template> 
                    <template v-slot:[`item.historico`]="{ item }">
                        <v-icon
                            small
                            class="mx-2"
                            color="primary"
                            @click="historico(item)"
                            title="Histórico"
                            v-if="item.id_planejamento"
                        >
                            mdi-file-document-multiple
                        </v-icon>
                    </template>
                    <template v-for="index in 12" v-slot:[`item.mes_${index}_disponivel`]="{ item }">
                        <div
                            :key="index"
                            @click="desdobramento.acessoPai ? navegar(item, 'pai') : ''"
                            :style="desdobramento.acessoPai ? 'cursor: pointer;' : ''"
                        >
                            {{(item['mes_'+index+'_disponivel']*1).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}
                        </div>
                    </template>
                    <template v-for="index in 12" v-slot:[`item.mes_${index}_faturado`]="{ item }">
                        <div
                            :key="index"
                        >
                            {{(item['mes_'+index+'_faturado']*1).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}
                        </div>
                    </template>
                    <template v-for="index in 12" v-slot:[`item.mes_${index}_percentual`]="{ item }">
                        <div
                            :key="index"
                            @click="fnCriarEditar && desdobramento.tipo == 'Faturamento' ? abrir(item, 'mes_'+index+'_percentual') : ''"
                            :style="fnCriarEditar && desdobramento.tipo == 'Faturamento' ? 'cursor: pointer;' : ''"
                        >
                            {{(item['mes_'+index+'_percentual']*1).toLocaleString('pt-br', {style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2})+'%'}}
                        </div>
                    </template>
                    <template v-for="index in 12" v-slot:[`item.mes_${index}`]="{ item }">
                        <div
                            :key="index"
                            @click="fnCriarEditar && desdobramento.tipo == 'Planejado' ? abrir(item, 'mes_'+index) : ''"
                            :style="fnCriarEditar && desdobramento.tipo == 'Planejado' ? 'cursor: pointer;' : ''"
                        >
                            {{(item['mes_'+index]*1).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}
                        </div>
                    </template>
                    <template v-for="index in 12" v-slot:[`item.mes_${index}_solicitado`]="{ item }">
                        <div
                            :key="index"
                            @click="remanejamentoVerba(item, index)"
                            :style="'cursor: pointer;'"
                        >
                            {{(item['mes_'+index+'_solicitado']*1).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}
                        </div>
                    </template>
                    <template v-for="index in 12" v-slot:[`item.mes_${index}_aprovado`]="{ item }">
                        <div
                            :key="index"
                            @click="remanejamentoVerba(item, index)"
                            :style="'cursor: pointer;'"
                        >
                            {{(item['mes_'+index+'_aprovado']*1).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}
                        </div>
                    </template>
                    <template v-for="index in 12" v-slot:[`item.mes_${index}_devolvido`]="{ item }">
                        <div
                            :key="index"
                            @click="remanejamentoVerba(item, index)"
                            :style="'cursor: pointer;'"
                        >
                            {{(item['mes_'+index+'_devolvido']*1).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}
                        </div>
                    </template>
                    <template v-for="index in 12" v-slot:[`item.mes_${index}_distribuido`]="{ item }">
                        <div
                            :key="index"
                            @click="desdobramento.acessoFilho ? navegar(item, 'filho') : ''"
                            :style="desdobramento.acessoFilho ? 'cursor: pointer;' : ''"
                        >
                            {{(item['mes_'+index+'_distribuido']*1).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}
                        </div>
                    </template>
                    <template v-for="index in 12" v-slot:[`item.mes_${index}_adistribuir`]="{ item }">
                        <div
                            :key="index"
                            @click="desdobramento.acessoFilho ? navegar(item, 'filho') : ''"
                            :style="desdobramento.acessoFilho ? 'cursor: pointer;' : ''"
                        >
                            {{(item['mes_'+index+'_adistribuir']*1).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}
                        </div>
                    </template>
                    <template v-for="index in 12" v-slot:[`item.mes_${index}_utilizado`]="{ item }">
                        <div :key="index">
                            {{(item['mes_'+index+'_utilizado']*1).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}
                        </div>
                    </template>
                    <template v-for="index in 12" v-slot:[`item.mes_${index}_utilizado_p`]="{ item }">
                        <div :key="index">
                            {{item['mes_'+index+'_utilizado_p'] + '%'}}
                        </div>
                    </template>
                    <template v-for="index in 12" v-slot:[`item.mes_${index}_saldo`]="{ item }">
                        <div :key="index">
                            {{(item['mes_'+index+'_saldo']*1).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}
                        </div>
                    </template>
                    <template v-slot:[`item.total_ano_disponivel`]="{ item }">
                        <div
                            @click="desdobramento.acessoPai ? navegar(item, 'pai') : ''"
                            :style="desdobramento.acessoPai ? 'cursor: pointer;' : ''"
                        >
                            {{(item.total_ano_disponivel*1).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}
                        </div>
                    </template>
                    <template v-slot:[`item.total_ano_faturado`]="{ item }">
                        <div>
                            {{(item.total_ano_faturado*1).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}
                        </div>
                    </template>
                    <template v-slot:[`item.total_ano`]="{ item }">
                        <div
                            @click="desdobramento.periodo == 'Anual' && fnCriarEditar ? abrir(item, 'total_ano') : ''"
                            :style="desdobramento.periodo == 'Anual' && fnCriarEditar  ? 'cursor: pointer;' : ''"
                        >
                            {{(item.total_ano*1).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}
                        </div>
                    </template>
                    <template v-slot:[`item.total_ano_distribuido`]="{ item }">
                        <div
                            @click="desdobramento.acessoFilho ? navegar(item, 'filho') : ''"
                            :style="desdobramento.acessoFilho ? 'cursor: pointer;' : ''"
                        >
                            {{(item.total_ano_distribuido*1).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}
                        </div>
                    </template>
                    <template v-slot:[`item.total_ano_adistribuir`]="{ item }">
                        <div
                            @click="desdobramento.acessoFilho ? navegar(item, 'filho') : ''"
                            :style="desdobramento.acessoFilho ? 'cursor: pointer;' : ''"
                        >
                            {{(item.total_ano_adistribuir*1).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}
                        </div>
                    </template>
                    <template v-slot:[`item.total_ano_solicitado`]="{ item }">
                        <div
                            @click="remanejamentoVerba(item, 'total_ano')"
                            style="cursor: pointer;"
                        >
                            {{(item.total_ano_solicitado*1).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}
                        </div>
                    </template>
                    <template v-slot:[`item.total_ano_aprovado`]="{ item }">
                        <div
                            @click="remanejamentoVerba(item, 'total_ano')"
                            style="cursor: pointer;"
                        >
                            {{(item.total_ano_aprovado*1).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}
                        </div>
                    </template>
                    <template v-slot:[`item.total_ano_devolvido`]="{ item }">
                        <div
                            @click="remanejamentoVerba(item, 'total_ano')"
                            style="cursor: pointer;"
                        >
                            {{(item.total_ano_devolvido*1).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}
                        </div>
                    </template>
                    <template v-slot:[`item.total_ano_utilizado`]="{ item }">
                        <div>
                            {{(item.total_ano_utilizado*1).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}
                        </div>
                    </template>
                    <template v-slot:[`item.total_ano_utilizado_p`]="{ item }">
                        <div>
                            {{item.total_ano_utilizado_p*1 + '%'}}
                        </div>
                    </template>
                    <template v-slot:[`item.total_ano_saldo`]="{ item }">
                        <div>
                            {{(item.total_ano_saldo*1).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}
                        </div>
                    </template>
                </v-data-table>
            </v-expand-transition>
        </v-card>

        <!-- Dialogo de alterar valor -->
        <v-dialog
            v-model="dialogo"
            width="440"
        >  
            <v-card>
                <v-card-title class="subtitle-1 primary white--text">
                    {{tituloDialogo}}
                </v-card-title>
                <v-card-text class="mt-4">
                    <v-form ref="form">
                        <v-row no-gutters class="mt-4">
                            <v-col cols="12">
                                <vuetify-money
                                    :label="desdobramento.tipo == 'Faturamento' ? 'Percentual' : 'Valor'"
                                    v-model="obj.valor"
                                    class="input-moeda"
                                    outlined
                                    dense 
                                    hide-details
                                    :options="desdobramento.tipo == 'Faturamento' ? options_percentual : options"
                                    clearable="clearable"
                                    backgroundColor="transparent"
                                ></vuetify-money> 
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        class="pr-4"
                        @click="dialogo = false; alerta = false;"
                    >
                        <v-icon left>mdi-close</v-icon> Fechar
                    </v-btn>
                    <v-btn
                        color="primary"
                        class="pr-4"
                        @click="salvar('salvar')"
                    >
                        <v-icon left>mdi-content-save</v-icon> Salvar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Histórico -->
        <v-dialog
            v-model="dialogoHistorico"
            width="90%"
        >  
            <v-card>
                <v-card-title class="subtitle-1 primary white--text">
                    Histórico
                </v-card-title>
                <v-card-text class="mt-4"> 
                    <v-data-table
                        :headers="headersHistorico"
                        :items="dadosHistorico"
                        :items-per-page="5"
                        :footer-props="{
                            'items-per-page-options': [5, 10, 15, 25, 50, 100]
                        }"
                        class="elevation-0"
                    >
                    </v-data-table> 
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        class="pr-4"
                        @click="dialogoHistorico = false"
                    >
                        <v-icon left>mdi-close</v-icon> Fechar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Histórico Faturamento -->
        <v-dialog
            v-model="faturamentoHistorico.dialogo"
            width="90%"
        >  
            <v-card>
                <v-card-title class="subtitle-1 primary white--text">
                    Histórico de Atualizações de Faturamento
                </v-card-title>
                <v-card-text class="mt-4"> 
                    <v-data-table
                        :headers="faturamentoHistorico.headers"
                        :items="faturamentoHistorico.dados"
                        :items-per-page="5"
                        :footer-props="{
                            'items-per-page-options': [5, 10, 15, 25, 50, 100]
                        }"
                        class="elevation-0"
                    >
                    </v-data-table> 
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        class="pr-4"
                        @click="faturamentoHistorico.dialogo = false"
                    >
                        <v-icon left>mdi-close</v-icon> Fechar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Importação -->
        <v-dialog
            v-model="dialogoImportar"
            width="90%"
        >  
            <v-card>
                <v-card-title class="subtitle-1 primary white--text">
                    Importar - {{desdobramento.nome}} - {{filtro.ano}}
                </v-card-title>
                <v-card-text>
                    <v-row no-gutters class="mt-4">  
                        <v-col cols="12">
                            <v-spacer></v-spacer>
                            <v-btn
                                class="ml-2 my-1"
                                color="primary"
                                @click="exportar(true)"
                            >
                                <v-icon left>mdi-microsoft-excel</v-icon> Exportar Modelo
                            </v-btn> 
                        </v-col> 
                    </v-row> 
                    <v-alert
                        class="mt-4"
                        text
                        type="info"
                        border="left"
                        style="font-size: 12px;"
                    >
                        <ul>
                            <li>
                                Os nomes das colunas devem ser <b>idêntico</b> ao da base exportada.
                            </li>
                            <li>
                                Todas as colunas são <b>obrigatórias</b>.
                            </li>
                            <li>
                                Se houver mais de uma linha com os mesmos <b>atributos</b>, só será considerada a primeira linha, as demais linhas com <b>atributos duplicados</b> serão desconsideradas.
                            </li>
                            <li v-if="desdobramento.tipo == 'Planejado'">
                                As colunas <b>Planejado</b> devem estar no formato moeda "<b>R$ 0.000,00</b>" com virgula "," como separador decimal e ponto "." como separador de milhar.
                            </li>
                            <li v-if="desdobramento.tipo == 'Planejado'">
                                Valores <b>planejados</b> que não estiverem no formato moeda "<b>R$ 0.000,00</b>" serão considerados como <b>0</b> (zero).
                            </li>
                            <li v-if="desdobramento.tipo == 'Faturamento'">
                                As colunas <b>percentual</b> devem estar no formato "<b>00,00%</b>" com virgula "," como separador decimal.
                            </li>
                            <li v-if="desdobramento.tipo == 'Faturamento'">
                                Valores <b>percentuais</b> que não estiverem no formato "<b>00,00%</b>" serão considerados como <b>0</b> (zero).
                            </li>
                        </ul> 
                    </v-alert> 
                    <v-row no-gutters>  
                        <v-col cols="8" class="px-2">
                            <v-form ref="form" v-model="isCsv">
                                <v-file-input 
                                    label="Arquivo CSV" 
                                    outlined 
                                    dense
                                    v-model="arquivoCSV"
                                    :rules="regra.csv"
                                    accept=".csv"
                                ></v-file-input>
                            </v-form>
                        </v-col> 
                        <v-col cols="4" class="px-2">
                            <v-btn
                                color="primary"
                                :disabled="!isCsv"
                                @click="importar()"
                            >
                                <v-icon left>mdi-upload</v-icon> 
                                Importar Arquivo
                            </v-btn>
                        </v-col> 
                    </v-row> 
                    <v-row no-gutters  class="mx-2" v-show="progresso">
                        <v-col cols="12" class="px-2">
                            {{ labelProgresso }}
                        </v-col>
                        <v-col cols="12" class="px-2">
                            <v-progress-linear 
                                v-model="indexProgresso" 
                                height="25" 
                            >
                                <span class="font-weight-bold white--text">{{ Math.ceil(indexProgresso) }}%</span>
                            </v-progress-linear>
                        </v-col>
                    </v-row>
                    <v-expand-transition>
                        <div v-show="erro">
                            <v-row no-gutters class="mb-0">  
                                <v-col cols="6">
                                    <download-csv
                                        :data="erros"
                                        :labels="errosLabels"
                                        name= "erros.csv"
                                        delimiter=";"
                                    >
                                        <v-btn color="primary">
                                            <v-icon left>mdi-microsoft-excel</v-icon>
                                            Exportar Logs
                                        </v-btn>
                                    </download-csv>
                                </v-col>
                            </v-row> 
                            <v-data-table
                                :headers="headerErros"
                                :items="erros"
                                :items-per-page="5"
                                :options.sync="paginationErros" 
                                class="elevation-0 data-table-2"
                                :item-class="errorClass"
                            ></v-data-table>
                        </div>
                    </v-expand-transition>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        class="pr-4"
                        @click="dialogoImportar=false"
                    >
                        <v-icon left>mdi-close</v-icon> Fechar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Remanejamento -->
        <v-dialog
            v-model="remanejamento.dialogo"
            width="90%"
        >  
            <v-card>
                <v-card-title class="subtitle-1 primary white--text">
                    {{remanejamento.titulo}}
                </v-card-title>
                <v-card-text>
                    <v-row no-gutters class="mt-4"> 
                        <v-col v-for="(obj, index) in desdobramento.tabelas" :key="index" cols="6" class="px-2">
                            <b>{{obj.nome}}: </b>
                            {{remanejamento.obj[obj.tabela]}}
                        </v-col>
                    </v-row>
                    <v-row no-gutters class="mt-4">
                        <v-col cols="4">
                            <v-text-field
                                v-model="remanejamento.busca"
                                append-icon="mdi-magnify"
                                label="Pesquisar"
                                dense 
                                outlined
                                hide-details
                            ></v-text-field>
                        </v-col>
                        <v-col cols="4">
                            <v-autocomplete
                                label="Status"
                                :items="remanejamento.dados.map(e => e.status)"
                                v-model="remanejamento.status"
                                multiple
                                clearable
                                outlined
                                dense 
                                class="ml-4"
                                hide-details
                            >
                                <template v-slot:selection="{item, index}">
                                    <div 
                                        v-if="remanejamento.status.length === 1 && index === 0"
                                        style="width: 70% !important; white-space: nowrap !important; text-overflow: ellipsis !important; overflow: hidden;"
                                    >{{ item }}</div>
                                    <span
                                        v-if="remanejamento.status.length > 1 && index === 0"
                                        class="grey--text caption mr-1"
                                    >{{ remanejamento.status.length }} selecionados</span>
                                </template>
                            </v-autocomplete>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-btn
                            v-if="fnSolicitarDevolver && remanejamento.mes != 'total_ano'"
                            color="primary"
                            class="pr-4"
                            @click="solicitar"
                        >
                            <v-icon left>mdi-plus</v-icon> Solicitar Verba
                        </v-btn>
                        <v-btn
                            v-if="fnSolicitarDevolver && remanejamento.mes != 'total_ano'"
                            color="error"
                            class="pr-4 ml-4"
                            @click="devolver"
                        >
                            <v-icon left>mdi-minus</v-icon> Devolver Verba
                        </v-btn>
                    </v-row>
                    <v-data-table
                        :headers="remanejamento.headers"
                        :items="remanejamento.dados.filter(e => (remanejamento.status.length == 0 || remanejamento.status.includes(e.status)))"
                        :search="remanejamento.busca"
                        :items-per-page="5"
                        :footer-props="{
                            'items-per-page-options': [5, 10, 15, 25, 50, 100]
                        }"
                        :options.sync="remanejamento.pagination" 
                        class="elevation-0 mt-4"
                    >   
                        <template v-slot:[`item.botao`]="{ item }"> 
                            <v-icon
                                v-if="fnSolicitarDevolver && !item.usuario_aprovacao && item.status != 'devolvido'"
                                small
                                class="mr-2"
                                color="primary"
                                @click="removerRemanejamento(item)"
                                title="Remover Remanejamento"
                            >
                                mdi-trash-can
                            </v-icon>
                            <v-icon
                                v-if="item.id_aprovacao && fnAprovarRemanejamento.includes(item.id_menu_aprovacao)"
                                small
                                class="mr-2"
                                color="success"
                                @click="processar(item, 'aprovarRemanejamento')"
                                title="Aprovar Remanejamento"
                            >
                                mdi-check
                            </v-icon>
                            <v-icon
                                v-if="item.id_aprovacao && fnAprovarRemanejamento.includes(item.id_menu_aprovacao)"
                                small
                                class="mr-2"
                                color="error"
                                @click="processar(item, 'reprovarRemanejamento')"
                                title="Reprovar Remanejamento"
                            >
                                mdi-close
                            </v-icon>
                        </template>
                    </v-data-table> 
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        class="pr-4"
                        @click="remanejamento.dialogo=false"
                    >
                        <v-icon left>mdi-close</v-icon> Fechar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Remanejamento valor -->
        <v-dialog
            v-model="remanejamento.dialogoValo.dialogo"
            width="300"
        >  
            <v-card>
                <v-card-title :class="'subtitle-1 white--text ' + remanejamento.dialogoValo.color">
                     {{remanejamento.dialogoValo.titulo}}
                </v-card-title>
                <v-card-text class="mt-4">
                    <v-form ref="form"> 
                        <v-row no-gutters class="mt-4">
                            <v-col cols="12">
                                <vuetify-money
                                    label="Valor"
                                    v-model="remanejamento.valor"
                                    class="input-moeda"
                                    outlined
                                    dense 
                                    hide-details
                                    :options="options"
                                    clearable="clearable"
                                    backgroundColor="transparent"
                                ></vuetify-money>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        class="pr-4"
                        @click="remanejamento.dialogoValo.dialogo = false;"
                    >
                        <v-icon left>mdi-close</v-icon> Fechar
                    </v-btn>
                    <v-btn
                        :color="remanejamento.dialogoValo.color"
                        class="pr-4"
                        @click="salvarRemanejamento"
                    >
                        <v-icon left>mdi-content-save</v-icon> {{remanejamento.dialogoValo.botao}}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Remanejamento Confirm -->
        <v-dialog
            v-model="remanejamento.confirm.dialogo"
            width="600"
        >  
            <v-card>
                <v-card-title class="subtitle-1 primary white--text">
                    {{remanejamento.confirm.titulo}}
                </v-card-title>
                <v-card-text class="mt-4"> 
                    {{remanejamento.confirm.texto}}
                    <br>
                    <br>
                    <v-form ref="formConfirm">
                        <v-select
                            v-if="remanejamento.confirm.justificativas"
                            label="Justificativa"
                            v-model="remanejamento.confirm.justificativa"
                            :items="remanejamento.confirm.justificativas"
                            :rules="regra.justificativa"
                            outlined
                            dense 
                        ></v-select>
                    </v-form>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        class="pr-4"
                        @click="remanejamento.confirm.dialogo=false"
                    >
                        <v-icon left>mdi-close</v-icon> Fechar
                    </v-btn>
                    <v-btn
                        :color="remanejamento.confirm.color"
                        class="pr-4"
                        @click="remanejamento.confirm.funcao"
                    >
                        <v-icon left>{{remanejamento.confirm.icon}}</v-icon> {{remanejamento.confirm.botao}}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-overlay :value="carregando" :z-index="300">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        
        <v-snackbar
            v-model="alerta"
            :color="alertaColor"
            :timeout="alertaTimeout"
            top
            style="z-index: 400"
        >
            <v-alert :type="alertaColor" v-html="alertaTexto">
            </v-alert>        
            <v-btn
                dark
                text
                class="float-right"
                @click="alerta=false"
            >
            Fechar
            </v-btn>
        </v-snackbar>  

    </v-container>
</template>

<script>

export default {
    data() {
        return {
            mostrar: {
                filtros: {flag: true, icone: 'mdi-chevron-up', nome: 'Filtros'},
                indicadores: {flag: true, icone: 'mdi-chevron-up', nome: 'Indicadores'},
                lista: {flag: true, icone: 'mdi-chevron-up', nome: 'Listagem'},
            },
            indicadoresTotal: [],
            dados: [],
            pagination: {
                page: 1,
                itemsPerPage: 5,
                sortBy: [],
                sortDesc: [],
                groupBy: [],
                groupDesc: [],
                mustSort: false,
                multiSort: true
            },
            total: null,
            busca: null,
            lista: {},
            filtro: {
                ano: new Date().getFullYear(),
                historico: 'Base Atual',
                planejado: '> 0',
                indicador: null,
            },
            desdobramento: {
                nome: null,
                tabelas: [],
                desdobramento_filhos: [],
                carregado: false
            },
            obj: {
                valor: null,
            },
            options: {
                locale: "pt-BR",
                prefix: "R$",
                suffix: "",
                length: 11,
                precision: 2
            },
            options_percentual: {
                locale: "pt-BR",
                prefix: "",
                suffix: "%",
                length: 11,
                precision: 2
            },
            headersHistorico: [],
            dadosHistorico: [],
            tituloDialogo: null, 
            dialogo: false,
            dialogoImportar: false,
            dialogoHistorico: false,
            meses: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro', 'Total Ano'],
            mesesHeader: [],
            indicadores: [],
            indicadoresHeader: [],
            carregando: false,
            alerta: false,
            alertaTexto: '',
            alertaColor: 'error',
            alertaTimeout: 3000,
            arquivoCSV: null,
            regra: { 
                csv: [
                    v => !!v || 'Selecione um arquivo.',
                    v => (!!v && [
                        '.csv', 
                        '.CSV'
                    ].includes(v.name.substr(-4))) || 'O arquivo deve ser do tipo CSV.',
                ],
                justificativa: [v => !!v || 'Campo obrigatório.'],
            },
            isCsv: false,
            headerErros: [
                { text: 'Tipo', value: 'tipo', align: 'center'},
                { text: 'Linha', value: 'linha', align: 'center'},
                { text: 'Descrição', value: 'msg', align: 'center'},
            ],
            erros: [],
            errosLabels: {msg: 'Descrição'},
            erro: false,
            paginationErros: {},
            progresso: false,
            indexProgresso: 0,
            labelProgresso: null,
            configuracoes: [],
            menuConfiguracoes: false,
            fnDisponivel: false,
            fnADistribuir: false,
            fnCriarEditar: false,
            fnSolicitarDevolver: false,
            fnAprovarRemanejamento: [],
            fnAprovar: false,
            remanejamento: {
                dialogo: false,
                titulo: 'Solicitar Verba',
                obj: {},
                pagination: {}, 
                busca: null,
                status: [],
                dados: [], 
                headers: [
                    { text: 'Responsável', value: 'usuario_criacao', align: 'center', sortable: false },
                    { text: 'Data', value: 'data_criacao', align: 'center', sortable: false },
                    { text: 'Mês', value: 'mes', align: 'center', sortable: false },
                    { text: 'Valor', value: 'valor_nome', align: 'center', sortable: false },
                    { text: 'Status', value: 'status', align: 'center', sortable: false },
                    { text: 'Data Apro./Repro.', value: 'data_aprovacao', align: 'center', sortable: false },
                    { text: 'Apro./Repro.', value: 'usuario_aprovacao', align: 'center', sortable: false },
                    { text: 'Justificativa', value: 'justificativa', align: 'center', sortable: false },
                    { text: '', value: 'botao', align: 'center', sortable: false },
                ],
                dialogoValo:{
                    dialogo: false,
                    titulo: null,
                    color: null,
                    botao: null,
                },
                valor: 0,
                mes: null,
                tipo: null,
                item: {},
                confirm: {
                    dialogo: false,
                    titulo: null,
                    texto: null,
                    funcao: null,
                    color: null,
                    botao: null,
                    icon: null,
                },
            },
            faturamentoHistorico: {
                headers: [
                    { text: 'Responsável', value: 'usuario', align: 'center', sortable: false },
                    { text: 'Data', value: 'data', align: 'center', sortable: false },
                ],
                dados: [],
                dialogo: false,
            }
        }
    },
    watch: {
        pagination: {
            handler () {
                if(this.desdobramento.carregado) this.filtrar()
            },
            deep: true,
        },
        busca(v) {

            setTimeout(() => {
                if(v == this.busca){
                    
                    this.filtrar()
                }
            }, 1000)
        },
        '$route' () {

            this.buscaDesdobramento() 
        }
    },
    async created() {
       
        this.configuracoes = [
            { nome: 'Manter Ano', campo: 'manterAno', ativo: true},
            { nome: 'Manter Planejado', campo: 'manterPlanejado', ativo: false},
            { nome: 'Manter Histórico', campo: 'manterHistorico', ativo: false},
            { nome: 'Manter Atributos', campo: 'manterAtributos', ativo: true},
            { nome: 'Manter Pesquisa', campo: 'manterPesquisa', ativo: true},
            { nome: 'Manter Meses', campo: 'manterMeses', ativo: true},
            { nome: 'Manter Indicadores', campo: 'manterIndicadores', ativo: true},
        ]

        for (const c of this.configuracoes) {

            if(localStorage.getItem(c.campo)) {

                c.ativo = localStorage.getItem(c.campo) == 'true' ? true : false
            }
        }
       
        this.buscaDesdobramento()
    },
    computed: {
        headersGroup() {

            var indicadores = this.indicadoresHeader.length ? this.indicadores.filter(e => this.indicadoresHeader.includes(e.text)) : this.indicadores

            var headersGroup = [
                { text: null, colspan: 1, class: 'v-data-table-header text-center'},
            ]

            for (const t of this.desdobramento.tabelas) {

                headersGroup.push({ text: null, colspan: 1, class: 'v-data-table-header text-center'})  
                
                if (t.public_tabela == 'setor') {

                    headersGroup.push({ text: null, colspan: 1, class: 'v-data-table-header text-center'})
                }
            }

            var classe = 'primary darken-1'
            var colspan = indicadores.length

            if(this.desdobramento.periodo == "Mensal") {

                for (let m = 1; m <= 12; m++) {

                    if(!this.mesesHeader.includes(this.meses[m - 1]) && this.mesesHeader.length) continue
                    
                    headersGroup.push({ text: this.meses[m - 1], colspan: colspan, class: 'v-data-table-header text-center '+classe})  
                    
                    classe = classe == 'primary darken-1' ? '' : 'primary darken-1'
                } 
            }

            if(this.mesesHeader.includes(this.meses[12]) || !this.mesesHeader.length) {

                if (this.desdobramento.tipo == 'Faturamento') colspan = colspan -1

                headersGroup.push({ text: 'Total Ano', colspan: colspan, class: 'v-data-table-header text-center '+classe})
            }

            //if (this.fnCriarEditar) headersGroup.push({ text: null, colspan: 1, class: 'v-data-table-header text-center'})
            
            return headersGroup
        },
        headers() {

            var indicadores = this.indicadoresHeader.length ? this.indicadores.filter(e => this.indicadoresHeader.includes(e.text)) : this.indicadores

            var headers = [
                { text: '', value: 'historico', align: 'center', sortable: false },
            ]

            for (const t of this.desdobramento.tabelas) {
              
                headers.push({ text: t.nome, value: t.tabela, align: 'center'})

                if (t.public_tabela == 'setor') {

                    headers.push({ text: t.nome.replace('Setor ', ''), value: t.tabela.replace('setor', 'usuario'), align: 'center'})
                }
            }

            var classe = 'primary darken-1'

            if (this.desdobramento.periodo == "Mensal") {

                for (let m = 1; m <= 12; m++) {

                    if (!this.mesesHeader.includes(this.meses[m - 1]) && this.mesesHeader.length) continue

                    for (const i of indicadores) {
                        
                        headers.push({ text: i.text, value: 'mes_'+m+i.sufixo, align: 'center', class: classe})
                    }
                    
                    classe = classe == 'primary darken-1' ? '' : 'primary darken-1'
                } 
            }

            for (const i of indicadores) {

                if ( i.text != 'Percentual' && (this.mesesHeader.includes(this.meses[12]) || !this.mesesHeader.length) ) {
                
                    headers.push({ text: i.text, value: 'total_ano'+i.sufixo, align: 'center', class: classe})
                }
            }

            return headers
        },
    },
    methods: {
        changeConfiguracoes(item) {

            localStorage.setItem(item.campo, item.ativo)
        },  
        async buscaDesdobramento() {

            this.carregando = true

            this.desdobramento.carregado = false

            var data = {
                id_desdobramento: this.$route.params.id*1,
                id_cargo: this.$store.getters.getCargo.id_cargo
            }

            var retorno = await this.$http().post(`/planejamento/desdobramento`, {data: data})

            this.desdobramento = retorno.data.desdobramento

            this.fnDisponivel = retorno.data.fnDisponivel
            this.fnADistribuir = retorno.data.fnADistribuir
            this.fnCriarEditar = retorno.data.fnCriarEditar
            this.fnSolicitarDevolver = retorno.data.fnSolicitarDevolver
            this.fnAprovarRemanejamento = retorno.data.fnAprovarRemanejamento

            this.lista = {}

            this.lista = retorno.data.lista

            this.pagination = {
                page: 1,
                itemsPerPage: 5,
                sortBy: [],
                sortDesc: [],
                groupBy: [],
                groupDesc: [],
                mustSort: false,
                multiSort: true
            } 

            this.indicadores = []
            this.indicadoresTotal = []

            if (this.desdobramento.id_desdobramento_pai && this.fnDisponivel) {

                this.indicadores.push({text: 'Disponível', sufixo: '_disponivel'})

                this.indicadoresTotal.push({
                    nome: 'DISPONÍVEL', 
                    valor: 0, 
                    sufixo: '_disponivel', 
                    desc: 'Total da verba disponível para ser planejada.', 
                    icone: 'mdi-bank', 
                    cor: 'cyan',
                })
            }

            if (this.desdobramento.tipo == 'Faturamento') {

                this.indicadores.push({text: 'Faturado', sufixo: '_faturado'})
                this.indicadores.push({text: 'Percentual', sufixo: '_percentual'})

                this.indicadoresTotal.push({
                    nome: 'FATURADO', 
                    valor: 0, 
                    sufixo: '_faturado', 
                    desc: 'Total faturado para ser utilizado no cálculo do planejado.', 
                    icone: 'mdi-cash', 
                    cor: 'cyan',
                })
            }

            this.indicadores.push({text: 'Planejado', sufixo: ''})

            this.indicadoresTotal.push({
                nome: 'PLANEJADO', 
                valor: 0, 
                sufixo: '', 
                desc: 'Total da verba planejada.', 
                icone: 'mdi-wallet', 
                cor: 'primary'
            })

            if (this.desdobramento.desdobramento_filhos.length) {

                this.indicadores.push({text: 'Distribuído', sufixo: '_distribuido'})

                this.indicadoresTotal.push({
                    nome: 'DISTRIBUÍDO', 
                    valor: 0, 
                    sufixo: '_distribuido', 
                    desc: 'Total da verba planejada para os desdobramentos posteriores.', 
                    icone: 'mdi-cash-multiple', 
                    cor: 'blue-grey', 
                    perc: 0, 
                    percSufixo: '_distribuido_p', 
                    percNome: 'Investimento vs Distribuído', 
                    percDesc: 'Percentual da verba planejada para os desdobramentos posteriores investida nos acordos comerciais (investimento dividido pelo distribuído)'
                })

                if (this.fnADistribuir) {

                    this.indicadores.push({text: 'A Distribuir', sufixo: '_adistribuir'})

                    this.indicadoresTotal.push({
                        nome: 'A DISTRIBUIR', 
                        valor: 0, 
                        sufixo: '_adistribuir', 
                        desc: 'Total da verba não planejada para os desdobramentos posteriores (Planejado menos distribuído).', 
                        icone: 'mdi-cash-refund', 
                        cor: 'deep-orange',
                        cor2: 'deep-orange darken-2',
                    })
                }
            }

            this.indicadores.push({text: 'Investimento', sufixo: '_utilizado'})
            this.indicadores.push({text: 'Investimento %', sufixo: '_utilizado_p'})

            this.indicadoresTotal.push({
                nome: 'INVESTIMENTO', 
                valor: 0, 
                sufixo: '_utilizado', 
                desc: 'Total da verba investida nos acordos comerciais.', 
                icone: 'mdi-currency-usd', 
                cor: 'teal', 
                perc: 0, 
                percSufixo: '_utilizado_p', 
                percNome: 'Investimento vs Planejado', 
                percDesc: 'Percentual da verba investida nos acordos comerciais (investimento dividido pelo planejado)',
                ml: ' ml-8',
            })

            //não mostra saldo / a investir nos iveis intermediarios somente nos nivel finais
            if (!this.desdobramento.desdobramento_filhos.length) {

                this.indicadores.push({text: 'A Investir', sufixo: '_saldo'})

                this.indicadoresTotal.push({
                    nome: 'A INVESTIR', 
                    valor: 0, 
                    sufixo: '_saldo', 
                    desc: 'Total da verba restante para investimento (planejado menos investimento).', 
                    icone: 'mdi-scale-balance', 
                    cor: 'purple',
                    cor2: 'purple darken-2',
                    mr: ' mr-5',
                })
            }
            else {

                this.indicadoresTotal[this.indicadoresTotal.length - 1].mr = ' mr-5'
            }
            
            
            if (this.desdobramento.id_desdobramento_pai && this.desdobramento.remanejamento.length) {

                this.indicadores.push({text: 'Solicitado', sufixo: '_solicitado'})
                this.indicadores.push({text: 'Aprovado', sufixo: '_aprovado'})
                this.indicadores.push({text: 'Devolvido', sufixo: '_devolvido'})
           
                this.indicadoresTotal.push({
                    nome: 'SOLICITADO',
                    valor: 0,
                    sufixo: '_solicitado',
                    desc: 'Total da verba solicitada pendente de aprovação.',
                    icone: 'mdi-cash-plus',
                    cor: 'warning',
                    filtro: 'solicitado',
                    filtroTitulo: 'Filtrar solicitações pendentes',
                })

                this.indicadoresTotal.push({
                    nome: 'APROVADO',
                    valor: 0,
                    sufixo: '_aprovado',
                    desc: 'Total da verba solicitada aprovada.',
                    icone: 'mdi-cash-check',
                    cor: 'success'
                })
            }

            this.headersHistorico = [
                { text: 'Responsável', value: 'usuario', align: 'center'},
                { text: 'Data', value: 'data', align: 'center'},
                { text: 'Metodo', value: 'metodo', align: 'center'},
            ]
            
            for (const t of this.desdobramento.tabelas) {

                if(!this.filtro['id_'+t.tabela]) {
                
                    this.filtro['id_'+t.tabela] = []
                }
            }

            if(this.desdobramento.periodo == "Mensal") {

                for (let m = 1; m <= 12; m++) {

                    if (this.desdobramento.tipo == 'Faturamento') {

                        this.headersHistorico.push({ text: 'Fat. ' + this.meses[(m - 1)], value: 'mes_'+m+'_faturado', align: 'center'})
                        this.headersHistorico.push({ text: '% ' + this.meses[(m - 1)], value: 'mes_'+m+'_percentual', align: 'center'})
                        this.headersHistorico.push({ text: 'Pla. ' + this.meses[(m - 1)], value: 'mes_'+m, align: 'center'})
                    }
                    else {

                        this.headersHistorico.push({ text: this.meses[(m - 1)], value: 'mes_'+m, align: 'center'})
                    }
                } 
            }

            if (this.desdobramento.tipo == 'Faturamento') {

                this.headersHistorico.push({ text: 'Fat. Total Ano', value: 'total_ano_faturado', align: 'center'})
                this.headersHistorico.push({ text: 'Pla. Total Ano', value: 'total_ano', align: 'center'})
            }
            else {

                this.headersHistorico.push({ text: 'Total Ano', value: 'total_ano', align: 'center'})
            }

            this.filtro.indicador = null
            
            if( !( this.configuracoes.find(e => e.campo == 'manterAno') ).ativo ) {
            
                this.filtro.ano = new Date().getFullYear()
            }

            if( !( this.configuracoes.find(e => e.campo == 'manterPlanejado') ).ativo ) {
            
                this.filtro.planejado = '> 0'
            }

            if( !( this.configuracoes.find(e => e.campo == 'manterHistorico') ).ativo ) {
            
                this.filtro.historico = 'Base Atual'
            }

            if( !( this.configuracoes.find(e => e.campo == 'manterAtributos') ).ativo ) {

                for (const t of this.desdobramento.tabelas) {
                
                    this.filtro['id_'+t.tabela] = []
                }
            }

            if( !( this.configuracoes.find(e => e.campo == 'manterPesquisa') ).ativo ) {

                this.busca = null
            }

            if( !( this.configuracoes.find(e => e.campo == 'manterMeses') ).ativo ) {

                this.mesesHeader = []
            }

            if( !( this.configuracoes.find(e => e.campo == 'manterIndicadores') ).ativo ) {

                this.indicadoresHeader = []
            }

            this.carregando = false 

            this.filtrar()
        },
        mostrarOcultar(tipo) {

            this.mostrar[tipo].flag = !this.mostrar[tipo].flag
            this.mostrar[tipo].icone = this.mostrar[tipo].flag ? 'mdi-chevron-up' : 'mdi-chevron-down'
        },
        limpar() {

            this.filtro.ano = new Date().getFullYear()
            this.filtro.historico = 'Base Atual'
            this.filtro.planejado = '> 0'
            this.filtro.indicador = null

            for (const t of this.desdobramento.tabelas) {

                this.filtro['id_'+t.tabela] = []
            }

            this.filtrar()
        },
        async filtrar() {

            this.dados = []

            if (!this.desdobramento.desdobramentoModelo.length) {

                this.alerta = true
                this.alertaColor = 'error'
                this.alertaTimeout = -1
                this.alertaTexto = 'Não existe nenhum Modelo de Acordo configurado para o desdobramento. É necessário um Modelo de Acordo para buscar o investimento do desdobramento.'
                return
            }

            this.carregando = true

            var data = {...this.desdobramento}
            data.pagination = {...this.pagination}
            data.busca = this.busca
            data.id_cargo = this.$store.getters.getCargo.id_cargo
            data.filtro = {...this.filtro}
            data.fnDisponivel = this.fnDisponivel
            data.fnADistribuir = this.fnADistribuir

            for (const t of this.desdobramento.tabelas) {
            
                if (t.fn_oculto) {

                    data.filtro['id_'+t.tabela] = []
                }
            }

            var response = await this.$http().post(`/planejamento/lista`, {data: data})   
            
            this.dados = response.data.lista
            this.total = response.data.lista.length ? response.data.indicadores[0].total : 0

            let indicadores = response.data.lista.length ? response.data.indicadores[0] : {
                total: 0,
                total_ano: "0",
                total_ano_solicitado: "0",
                total_ano_aprovado: "0",
                total_ano_devolvido: "0",
                total_ano_utilizado: "0"
            }

            indicadores.total_ano_saldo = indicadores.total_ano - indicadores.total_ano_utilizado
            indicadores.total_ano_utilizado_p = indicadores.total_ano*1 ? Math.round(100*indicadores.total_ano_utilizado/indicadores.total_ano) : 0
            indicadores.total_ano_distribuido_p = indicadores.total_ano_distribuido*1 ? Math.round(100*indicadores.total_ano_utilizado/indicadores.total_ano_distribuido) : 0
        

            for (const indicador of this.indicadoresTotal) {

                let propriedade = 'total_ano' + indicador.sufixo
                let valor = indicadores[propriedade]

                indicador.valor = valor ? valor*1 : 0

                if (indicador.percSufixo) {

                    propriedade = 'total_ano' + indicador.percSufixo
                    valor = indicadores[propriedade]

                    indicador.perc = valor ? valor*1 : 0
                }
            }

            if(this.total == 0 && this.pagination.page > 1) {

                this.pagination.page = 1
            }

            this.desdobramento.carregado = true

            this.carregando = false
        },
        async exportar(exportarModelo) {

            if (!this.desdobramento.desdobramentoModelo.length) {

                this.alerta = true
                this.alertaColor = 'error'
                this.alertaTimeout = -1
                this.alertaTexto = 'Não existe nenhum Modelo de Acordo configurado para o desdobramento. É necessário um Modelo de Acordo para buscar o investimento do desdobramento.'
                return
            }
            
            this.carregando = true

            var data = {...this.desdobramento}
            data.exportarModelo = exportarModelo

            if(exportarModelo) {

                this.filtro.planejado = null
            }

            data.busca = this.busca
            data.id_cargo = this.$store.getters.getCargo.id_cargo
            data.filtro = {...this.filtro}
            data.fnDisponivel = this.fnDisponivel
            data.fnADistribuir = this.fnADistribuir

            for (const t of this.desdobramento.tabelas) {
            
                if (t.fn_oculto) {

                    data.filtro['id_'+t.tabela] = []
                }
            }

            var response = await this.$http().post(`/planejamento/exportar`, {data: data})   

            window.location = this.$http('baseURL')+response.data.url

            this.carregando = false
        },
        async faturamento() {

            this.carregando = true

            var data = {...this.desdobramento}

            data.id_cargo = this.$store.getters.getCargo.id_cargo
            data.filtro = {...this.filtro}

            var response = await this.$http().post(`/planejamento/faturamento`, {data: data})

            this.carregando = false

            if(response.data.sucesso){

                this.filtrar()
                this.dialogo = false  
                this.alerta = false
            }
            else{

                this.alerta = true
                this.alertaColor = 'error'
                this.alertaTimeout = -1
                this.alertaTexto = response.data.msg
            }
        },
        async historicoFaturamento() {

            this.carregando = true

            var data = {...this.desdobramento}
            data.filtro = {...this.filtro}

            var response = await this.$http().post(`/planejamento/historicoFaturamento`, {data: data})

            this.faturamentoHistorico.dados = response.data

            this.carregando = false

            this.faturamentoHistorico.dialogo = true

            console.log(this.faturamentoHistorico)
        },
        abrir(item, campo) {

            this.dialogo = true

            this.tituloDialogo = campo == 'total_ano' ? 'Total Ano de ' + this.filtro.ano : this.meses[ ( campo.split('_') )[1] - 1 ] + ' de ' + this.filtro.ano

            this.obj.valor = item[campo]
            this.obj.campo = campo
            this.obj.item = item

            setTimeout(() => { document.querySelector('.input-moeda input').focus() }, 200)
        }, 
        async salvar(tipo) {
           
            //this.carregando = true
            
            var data = {...this.desdobramento}

            data.ano = this.filtro.ano
            data.obj = this.obj
            data.tipoSalvar = tipo

            var response = await this.$http().post(`/planejamento/salvar`, {data: data})
          
            this.carregando = false

            if(response.data.sucesso){

                this.filtrar()
                this.dialogo = false  
                this.alerta = false
            }
            else{

                this.alerta = true
                this.alertaColor = 'error'
                this.alertaTimeout = -1
                this.alertaTexto = response.data.msg
            }   
        }, 
        async historico(item) {
           
            this.carregando = true
            
            var data = {id_planejamento: item.id_planejamento, tipo: this.desdobramento.tipo}

            var response = await this.$http().post(`/planejamento/historico`, {data: data})   
          
            this.dadosHistorico = response.data
            
            this.carregando = false

            this.dialogoHistorico = true
        }, 
        abrirImportar() {

            this.arquivoCSV = null
            this.erro = false
            this.progresso = false

            try {
                this.$refs.form.resetValidation()
            } catch (error) {
                //console.log(error)
            }  

            this.dialogoImportar = true 
        },
        async importar() {

            if (!this.desdobramento.desdobramentoModelo.length) {

                this.alerta = true
                this.alertaColor = 'error'
                this.alertaTimeout = -1
                this.alertaTexto = 'Não existe nenhum Modelo de Acordo configurado para o desdobramento. É necessário um Modelo de Acordo para buscar o investimento do desdobramento.'
                return
            }

            this.carregando = true

            this.erro = false
            this.progresso = true
            this.indexProgresso = 0
            this.labelProgresso = 'Enviando arquivo.'

            var desdobramento = {...this.desdobramento}

            desdobramento.ano = this.filtro.ano

            var formData = new FormData()

            formData.append("files", this.arquivoCSV)
            formData.append("desdobramento", JSON.stringify(desdobramento))

            this.arquivoCSV = null

            try {
                this.$refs.form.resetValidation()
            } catch (error) {
                //console.log(error)
            }

            var response = {}
            
            await this.$http().post('/planejamento/importar', 
                formData,
                {
                    // onUploadProgress: (event) => {

                    //console.log(event.loaded, event.total) 
                    // },
                    onDownloadProgress: progressEvent => {

                        let retorno = progressEvent.currentTarget.response.split(";")

                        let obj = JSON.parse(retorno[(retorno.length - 2)])

                        this.indexProgresso = obj.index
                        this.labelProgresso = obj.label

                        response.sucesso = obj.sucesso ? obj.sucesso : null
                        response.erros = obj.erros ? obj.erros : null
                    }
                }
            )

            this.carregando = false
            this.filtrar()

            if(response.sucesso){

                this.dialogoImportar = false
            }
            else{

                this.progresso = false
                this.erros = response.erros
                this.paginationErros.page = 1
                this.erro = true
            }  
        },
        remanejamentoVerba(item, mes) {

            this.remanejamento.titulo = 'Remanejamento de Verba - ' + (mes == 'total_ano' ? 'Total Ano' : this.meses[mes-1])

            this.remanejamento.obj = {...item}
            this.remanejamento.obj.item = {...item}
            this.remanejamento.mes = mes
            this.remanejamento.busca = null
            this.remanejamento.status = []

            this.listaRemanejamento(false)

            this.remanejamento.dialogo = true
        }, 
        solicitar() {

            this.remanejamento.dialogoValo.dialogo = true
            this.remanejamento.dialogoValo.titulo = 'Valor da Verba Solicitada'
            this.remanejamento.dialogoValo.color = 'primary'
            this.remanejamento.dialogoValo.botao = 'Solicitar'
            this.remanejamento.tipo = 'solicitar'
            this.remanejamento.valor = 0
        }, 
        devolver() {

            this.remanejamento.dialogoValo.dialogo = true
            this.remanejamento.dialogoValo.titulo = 'Valor da Verba Devolvida'
            this.remanejamento.dialogoValo.color = 'error'
            this.remanejamento.dialogoValo.botao = 'Devolver'
            this.remanejamento.tipo = 'devolver'
            this.remanejamento.valor = 0
        }, 
        async salvarRemanejamento() {
           
            this.carregando = true

            var data = {...this.desdobramento}

            data.ano = this.filtro.ano
            data.obj = this.remanejamento.obj
            data.mes = this.remanejamento.mes
            data.valor = this.remanejamento.valor
            data.tipo = this.remanejamento.tipo
            
            var response = await this.$http().post(`/planejamento/salvarRemanejamento`, {data: data})

            this.carregando = false

            if(response.data.sucesso){

                this.listaRemanejamento()
                this.remanejamento.dialogoValo.dialogo = false
                this.alerta = false
            }
            else{

                this.alerta = true
                this.alertaColor = 'error'
                this.alertaTimeout = -1
                this.alertaTexto = response.data.msg
            }   
        }, 
        async removerRemanejamento(item) {
           
            this.carregando = true

            var data = {...item}
            
            var response = await this.$http().post(`/planejamento/removerRemanejamento`, {data: data})

            this.carregando = false

            if(response.data.sucesso){

                this.listaRemanejamento()
                this.remanejamento.dialogoValo = false
                this.alerta = false
            }
            else{

                this.alerta = true
                this.alertaColor = 'error'
                this.alertaTimeout = -1
                this.alertaTexto = response.data.msg
            }   
        }, 
        processar(item, processo) {
           
            this.remanejamento.item = {...item}
            this.remanejamento.confirm.justificativa = null

            if (processo == 'aprovarRemanejamento') {

                this.remanejamento.confirm.titulo = 'Aprovar Remanejamento'
                this.remanejamento.confirm.texto = 'Deseja realmente aprovar a solicitação de verba?'
                this.remanejamento.confirm.color = 'success'
                this.remanejamento.confirm.icon = 'mdi-check'
                this.remanejamento.confirm.botao = 'Aprovar'
                this.remanejamento.confirm.justificativas = false
                this.remanejamento.confirm.funcao = this[processo]
                this.remanejamento.confirm.dialogo = true
            }

            if (processo == 'reprovarRemanejamento') {

                this.remanejamento.confirm.titulo = 'Reprovar Remanejamento'
                this.remanejamento.confirm.texto = 'Deseja realmente reprovar a solicitação de verba?'
                this.remanejamento.confirm.color = 'error'
                this.remanejamento.confirm.icon = 'mdi-close'
                this.remanejamento.confirm.botao = 'Reprovar'
                this.remanejamento.confirm.justificativas = this.desdobramento.remanejamentoJustificativas.map(e => e.justificativa)
                this.remanejamento.confirm.funcao = this[processo]
                this.remanejamento.confirm.dialogo = true
            }
        }, 
        async aprovarRemanejamento() {
           
            this.carregando = true

            var data = {...this.desdobramento}

            data = {...data, ...this.remanejamento.item}
            data.obj = {...this.remanejamento.obj}
            data.ano = this.filtro.ano

            var response = await this.$http().post(`/planejamento/aprovarRemanejamento`, {data: data})

            this.carregando = false

            if(response.data.sucesso){

                this.listaRemanejamento()
                this.remanejamento.confirm.dialogo = false
                this.alerta = false
            }
            else{

                this.alerta = true
                this.alertaColor = 'error'
                this.alertaTimeout = -1
                this.alertaTexto = response.data.msg
            }   
        }, 
        async reprovarRemanejamento() {
           
            if (!this.$refs.formConfirm.validate()) return
            
            this.carregando = true

            var data = {...this.remanejamento.item}

            data.justificativa = this.remanejamento.confirm.justificativa
            
            var response = await this.$http().post(`/planejamento/reprovarRemanejamento`, {data: data})

            this.carregando = false

            if(response.data.sucesso){

                this.listaRemanejamento()
                this.remanejamento.confirm.dialogo = false  
                this.alerta = false
            }
            else{

                this.alerta = true
                this.alertaColor = 'error'
                this.alertaTimeout = -1
                this.alertaTexto = response.data.msg
            }   
        }, 
        async listaRemanejamento(fnFiltra = true) {
           
            this.carregando = true

            var data = {...this.desdobramento}

            data.ano = this.filtro.ano
            data.obj = this.remanejamento.obj
            data.mes = this.remanejamento.mes
            data.valor = this.remanejamento.valor
            
            var response = await this.$http().post(`/planejamento/listaRemanejamento`, {data: data})

            this.remanejamento.dados = response.data

            this.carregando = false

            if(fnFiltra) this.filtrar()
        }, 
        navegar() {


        }, 
        filtroIndicadore(indicador) {

            this.filtro.indicador = indicador
            this.filtrar()
        }, 
        errorClass(item) {

            return item.tipo == 'Erro Planejamento Inconsistente' ? 'errorClass' : ''
        },
        iconeSelecionarTudo(var_id, tabela) {

            if(this.filtro[var_id].length === this.lista[tabela].length) return 'mdi-close-box'
            if (this.filtro[var_id].length > 0 && !(this.filtro[var_id].length === this.lista[tabela].length)) return 'mdi-minus-box'
            return 'mdi-checkbox-blank-outline'
        },
        selecionarTudo (var_id, tabela) {

            this.carregando = true

            setTimeout(() => {

                if(this.filtro[var_id].length === this.lista[tabela].length) {

                    this.filtro[var_id] = []
                }
                else {

                    this.filtro[var_id] = this.lista[tabela].map(e => e.value)
                }
            }, 100)

            setTimeout(() => { this.carregando = false }, (this.lista[tabela].length > 100 ? this.lista[tabela].length : 110))
        },
        iconeSelecionarTudoExibicao (filtro, lista) {

            if(this[filtro].length === this[lista].length) return 'mdi-close-box'
            if (this[filtro].length > 0 && !(this[filtro].length === this[lista].length)) return 'mdi-minus-box'
            return 'mdi-checkbox-blank-outline'
        },
        selecionarTudoExibicao (filtro, lista, tipo=false) {

            this.carregando = true

            if(this[filtro].length === this[lista].length) {

                this[filtro] = []
            }
            else {

                if(tipo) {

                    this[filtro] = [...this[lista].map(e => e[tipo])]
                }
                else {

                    this[filtro] = [...this[lista]]
                }
            }

            setTimeout(() => { this.carregando = false }, 100)
        },
    },
}
</script>